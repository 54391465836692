import React ,{ Component} from 'react'

class Fourth extends Component {
  render(){
  return (
    <div>
        <div className="fourth-highlight-wrapper text-center row px-0">
  <div className="left-side-wrapper mt-2  pt-5 col-md ml-md-2 mr-md-1">
<h2 className="font-weight-bold">iPhone 13 Pro</h2>
<p>Oh. So. Pro</p>
<ul className="links-wrapper list-unstyled d-flex justify-content-center">
  <li className="pr-2"><a href="#">Learn more</a></li>
  <li className="pl-2"><a href="#">buy</a></li>
</ul>
  </div>

  <div className="right-side-wrapper pt-5 mt-2 col-md ml-md-1 mr-md-2">
    <h2 ></h2>
    <p></p>
    <ul className="links-wrapper list-unstyled d-flex justify-content-center">
      <li className="pr-2"><a href="#">Learn more</a></li>
      <li className="pl-2"><a href="#">buy</a></li>
    </ul>
  </div>
</div>
    </div>
  )
  }
}

export default Fourth