import React, { useState, useEffect } from "react";
import "./YoutubeVideos.css";

const YoutubeVideos = () => {
  const [youtubeVideos, setYoutubeVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCE_M8A5yxnLfW0KghEeajjw&maxResults=9&order=date&type=video&key=AIzaSyCx6sWQ99Pn6p_ObgAIoKRMx2aIg9T6oqQ`
        );
        const data = await response.json();
        console.log(data);
        setYoutubeVideos(data.items);
      } catch (error) {
        console.error("Something went wrong, Please Try again", error.message);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="allVideosWrapper">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <div className="titlewrapper">
              <br />
              <h1>Latest Videos</h1>
              <br />
            </div>
          </div>
          {youtubeVideos?.map((singleVideo) => {
            const videoId = singleVideo.id.videoId;
            const videoLink = `https://www.youtube.com/watch?v=${videoId}`;
            return (
              <div key={videoId} className="col-sm-12 col-md-6 col-lg-4">
                <div className="singleVideoWrapper">
                  <div className="videoThumbnail">
                    <a
                      href={videoLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={singleVideo.snippet.thumbnails.high.url}
                        alt={singleVideo.snippet.title}
                      />
                    </a>
                  </div>
                  <div className="videoInfoWrapper">
                    <div className="videoTitle">
                      <a
                        href={videoLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {singleVideo.snippet.title}
                      </a>
                    </div>
                    <div className="videoDescription">
                      {" "}
                      {singleVideo.snippet.description}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default YoutubeVideos;

